import { getDicts } from '@/api/system/dict/data' //字段的一些接口
import { deepCopy } from '@/utils'
import { listCategoryTree, listCategory } from '@/api/goods/category' //类别
import { listGoods } from '@/api/goods/goods' //商品资料
import { listlevel } from '@/api/vip/base/level' //会员等级
import { listVipCard } from '@/api/vip/base/vipCard' //会员列表
import { listBrand } from '@/api/goods/brand.js' //品牌列表
import { listShopInfo, getDeptTree } from '@/api/shop/base/shopInfo' //门店/部门
import { CouponList } from '@/api/marketing/coupon/case' //优惠券
import { packageListAPI } from '@/api/marketing/coupon/package' //优惠券包
import { treeselect as getDepTree } from '@/api/system/dept' //部门
import { listStore } from '@/api/goods/store' //仓库
import { listCustomer } from '@/api/goods/customer' //客户资料
import { listSupplier } from '@/api/goods/supplier' //供应商资料
import { produceOrderList } from '@/api/produce/sets/produceOrder' //生产班次接口
import { guideManListAPI } from '@/api/shop/saleGuide/guideMan' //导购员
import { getCashierTree } from '@/api/shop/base/shopCashierGroup' //收银员树
import { listShopCashier } from '@/api/shop/base/shopCashier' //收银员
import { listPaymode } from '@/api/shop/base/paymode.js' //付款方式
import { listMachine } from '@/api/shop/base/machine.js' //收银机
import { produceStoreList } from '@/api/produce/sets/produceDeptStore' //生产仓库
import { getBelongProduct } from '@/api/tenant/product/saas' //选择所属产品
import { listBankAccount } from '@/api/goods/finance' //选择账户
import { listMainGoods } from '@/api/combinStrip/index' //选择组装商品
import { listRpType } from "@/api/system/category/index"  //收入 支出类别
import {
  listBrand as listOrder
} from "@/api/freshlyBaked/base/teacher.js";
import {
  shopPoiQueryt //抖音门店
} from '@/api/O2OThird/douyin/shop' //抖音门店
import {
  goodsList,
  categoryLiStTree,
} from "@/api/O2OMall/goods/list";
import { shopInfoList } from "@/api/O2OMall/manage/shop";
import {
  goodsList as dianCanGoodsList,
  categoryLiStTree as dianCanCategoryLiStTree
} from '@/api/O2OThird/orderMealWxa/goods'
import {
  cakefreeselectGoodsList,
  cakefreeselectCategoryLiStTree,
} from '@/api/O2OThird/cakeFreeSelectApp/goods'
import {
  listPlanDetails
} from "@/api/produce/bill/matchMaterial"; //生产配料
import { insteadList } from '@/api/produce/bom/instead' //生产替代
import { getTenantProductToRenew } from '@/api/tenant/base/info' //产品
import { saasTenantList } from '@/api/tenant/base/info'     //商户
import {
  listSmartDistribute
} from "@/api/produce/bill/autoDistribute"; //智能分货
import {
  douYinShopList, //抖音门店分页列表
} from '@/api/O2OThird/douyin/shop' //抖音门店内部
import {
  getOrderList
} from '@/api/tenant/product/order' //选择产品弹窗
import { listDistributeObj } from '@/api/produce/bill/autoDistribute'     //分货对象
// 弹窗常用组件配置
export default ({ key, option }) => {
  let config = {}
  switch (key) {
    case 'addDistribute': //分货对象
    config = {
      //搜索
      search: {
        type: "filters",
        model: "",
        filters: [
          { filter: "query", label: "全部" },
          { filter: 'shopNos', label: '门店编码' },
          { filter: 'shopNames', label: '门店名称' }
        ],
      },
      table: {
        loading: false,
        mutiSelect: true,
        list: listDistributeObj,
        rowKey: 'shopId',
        rowClick: true,
        check: [],
        pagination: {
          total: 0,
          pageNum: 1,
          pageSize: 15,
        },
        columns: [
          {
            prop: 'shopNo',
            label: '门店编码',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'shopName',
            label: '门店名称',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'zjm',
            label: '助记码',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 150,
            align: 'center'
          }
        ]
      }
    }
    break
    case 'douYinShopList': //抖音门店内部
      config = {
        //搜索
        search: {
          type: "filters",
          model: "",
          filters: [
            { filter: "query", label: "全部" },
            { filter: 'shopNos', label: '分店编号' },
            { filter: 'shopNames', label: '分店名称' }
          ],
        },
        table: {
          loading: false,
          mutiSelect: true,
          list: douYinShopList,
          rowKey: 'shopId',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'shopNo',
              label: '分店编号',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'shopName',
              label: '分店名称',
              minWidth: 150,
              align: 'center'
            },
            {
              type: 'icons',
              prop: 'isMatchDouyinShop',
              label: '是否抖音门店',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  } ; font-size: 20px; font-weight: 700;`
              })
            },
            {
              prop: 'poiName',
              label: '抖音门店名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'poiAddress',
              label: '抖音门店地址',
              minWidth: 180,
              align: 'center'
            },
            {
              type: 'icons',
              prop: 'isMatchDouyinShop',
              label: '是否匹配',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  } ; font-size: 20px; font-weight: 700;`
              })
            },
            {
              type: 'icons',
              prop: 'isMatchDouyinShop',
              label: '是否同步门店',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  } ; font-size: 20px; font-weight: 700;`
              })
            },
          ]
        }
      }
      break
    case 'autoDistributeImport': //商户
      config = {
        //搜索
        search: {
          type: "filters",
          model: "",
          filters: [
            { filter: "query", label: "全部" },
            { filter: "billNos", label: "单据编号" },
            { filter: "goodsNos", label: "商品编码" },
            { filter: "goodsNames", label: "商品名称" },
          ],
        },
        table: {
          loading: false,
          mutiSelect: true,
          list: listSmartDistribute,
          rowKey: 'billDetailId',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'billNo',
              label: '单据编号',
              minWidth: '180'
            },
            { prop: 'billDate', label: '单据日期', minWidth: '120' },
            { prop: 'goodsNo', label: '商品编码', minWidth: '180' },
            { prop: 'goodsName', label: '商品名称', minWidth: '180' },
            { prop: 'barcode', label: '条码', minWidth: '180' },
            { prop: 'unitName', label: '单位', minWidth: '100' },
            { prop: 'goodsSpec', label: '规格', minWidth: '100' },
            { prop: 'importUnitQty', label: '生产计划数', minWidth: '120' },
            { prop: 'unitQty', label: '入库数', minWidth: '120' },
            { prop: 'unitEligibleQty', label: '合格数', minWidth: '120' },
          ]
        }
      }
      break
    case 'tenant': //商户
      config = {
        search: {
          type: "filters",
          model: "",
          filters: [
            { filter: "query", label: "全部" },
            { filter: "tenantNos", label: "商户编号" },
            { filter: "tenantNames", label: "商户名称" },
          ],
        },
        table: {
          isTenant: true,
          data: 'list',
          loading: false,
          mutiSelect: true,
          list: saasTenantList,
          rowKey: 'tenantId',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'tenantNo',
              label: '商户号',
              minWidth: '200'
            },
            { prop: 'simpleName', label: '商户简称', minWidth: '200' },
            {
              prop: 'logoUrl',
              type: 'image',
              label: '商户logo',
              minWidth: '100'
            },
            {
              prop: 'status',
              type: 'dicts',
              name: 'tenant_service_state',
              label: '服务状态',
              minWidth: '120'
            },
            { prop: 'validBegTime', label: '开始时间', minWidth: '155' },
            { prop: 'validEndTime', label: '结束时间', minWidth: '155' },
            { prop: 'productName', label: '产品', minWidth: '200' },
            { prop: 'contactMan', label: '商户负责人', minWidth: '150' },
            { prop: 'telephone', label: '负责人电话', minWidth: '150' },
            {
              prop: 'onlineUserCount',
              type: 'link',
              click: 'onlinePopulation',
              label: '在线人数',
              minWidth: '100'
            },
            { prop: 'usableUsers', label: '支持用户数', minWidth: '150' },
            { prop: 'usableShops', label: '支持门店数', minWidth: '150' },
            { prop: 'usablePosss', label: '支持站点数', minWidth: '150' },
            { prop: 'saleAgentName', label: '代理商', minWidth: '120' },
            { prop: 'serviceEmployeeName', label: '客服人员', minWidth: '120' },
            { prop: 'implementEmployeeName', label: '实施人员', minWidth: '120' },
            { prop: 'saleEmployeeName', label: '业务员', minWidth: '120' },
            { prop: 'remark', label: '备注', minWidth: '180' },
            { prop: 'createBy', label: '创建人', minWidth: '100' },
            { prop: 'createTime', label: '创建时间', minWidth: '160' }
          ]
        }
      }
      break
    case 'productToRenew': //产品
      config = {
        table: {
          loading: false,
          mutiSelect: true,
          list: getTenantProductToRenew,
          rowKey: 'productBookDetailId',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            { prop: 'belongProductName', label: '所属产品', minWidth: '200' },
            { prop: 'productNo', label: '产品编号', minWidth: '200' },
            { prop: 'productName', label: '产品名称', width: '200' },
            {
              prop: 'productCalcMode',
              activeText(row) {
                return row.productCalcMode === 1 ? '包年包月' : '数量/次数'
              },
              label: '计费模式',
              minWidth: '200'
            },
            { prop: 'validBegTime', label: '有效期开始', minWidth: '200' },
            { prop: 'validEndTime', label: '有效期结束', minWidth: '160' }
          ]
        }
      }
      break
    case 'instead': //生产替代
      config = {
        table: {
          loading: false,
          mutiSelect: true,
          list: insteadList,
          rowKey: 'lineId',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'usableGoodsNo',
              label: '替代商品编码',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'usableGoodsName',
              label: '替代商品名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'goodsSpec',
              label: '规格',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'usableGoodsUnitName',
              label: '单位',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'isMain',
              label: '是否主要替代',
              minWidth: 120,
              align: 'center',
              formatter: (propValue) => {
                return propValue ? "是" : '否';
              },
            },
            {
              prop: 'exchangeScale',
              label: '替代比例(%)',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160,
              align: 'center'
            },
          ]
        }
      }
      break
    case 'matchMaterialImport': //生产配料引入源单
      config = {
        treeQuery: '',
        queryParams: {
          pageNum: 1,
          pageSize: 15,
        },
        table: {
          loading: false,
          mutiSelect: true,
          list: listPlanDetails,
          rowKey: 'billDetailId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            billStatus: 2,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'billNo',
              label: '单据编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'billDate',
              label: '单据日期',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'produceDeptName',
              label: '生产工厂',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'produceGroupName',
              label: '生产组',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'produceOrderName',
              label: '生产班次',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'billStatusName',
              label: '单据状态',
              minWidth: 100,
              align: 'center'
            },
            {
              prop: 'goodsNo',
              label: '商品编码',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'goodsName',
              label: '商品名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'unitQty',
              label: '单位数量',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'barcode',
              label: '条码',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'goodsSpec',
              label: '规格',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'unitName',
              label: '单位名称',
              minWidth: 120,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'dianCanGoods': //点餐商品资料
      config = {
        treeQuery: '',
        treeApi: dianCanCategoryLiStTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'categoryId'
        },
        queryParams: {
          status: 0,
          goodsType: 1
        },
        search: {
          tip: '商品编码/商品名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'goodsNos', label: '商品编码' },
            { filter: 'goodsNames', label: '商品名称' },
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入商品编码、商品名称',
          list: dianCanGoodsList,
          rowKey: 'goodsId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'goodsNo',
              label: '商品编码',
              type: 'link',
              click: 'routerLink',
              minWidth: 180
            },
            {
              prop: 'goodsName',
              label: '商品名称',
              minWidth: 150
            },
            {
              type: 'image',
              prop: 'goodsUrl',
              label: '图片',
              minWidth: 150
            },
            {
              prop: 'categoryName',
              label: '分类',
              minWidth: 150
            },
            {
              prop: 'goodsSpec',
              label: '商品规格',
              minWidth: 150
            },
            {
              prop: 'unitName',
              label: '商品单位',
              minWidth: 150
            },
            {
              prop: 'status',
              label: '上架状态',
              minWidth: 100,
              formatter: v => (v == 1 ? '未上架' : '已上架')
            }
          ]
        }
      }
      break
    case 'cakeGoodsDialog': //蛋糕商品资料
      config = {
        treeQuery: '',
        treeApi: cakefreeselectCategoryLiStTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'categoryId'
        },
        queryParams: {
          status: 0,
          goodsType: 1
        },
        search: {
          tip: '商品编码/商品名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'goodsNos', label: '商品编码' },
            { filter: 'goodsNames', label: '商品名称' },
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入商品编码、商品名称',
          list: cakefreeselectGoodsList,
          rowKey: 'goodsId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'goodsNo',
              label: '商品编码',
              type: 'link',
              click: 'routerLink',
              minWidth: 180
            },
            {
              prop: 'goodsName',
              label: '商品名称',
              minWidth: 150
            },
            {
              type: 'image',
              prop: 'goodsUrl',
              label: '图片',
              minWidth: 150
            },
            {
              prop: 'categoryName',
              label: '分类',
              minWidth: 150
            },
            {
              prop: 'goodsSpec',
              label: '商品规格',
              minWidth: 150
            },
            {
              prop: 'unitName',
              label: '商品单位',
              minWidth: 150
            },
            {
              prop: 'status',
              label: '上架状态',
              minWidth: 100,
              formatter: v => (v == 1 ? '未上架' : '已上架')
            }
          ]
        }
      }
      break
    case 'douYinShop': //抖音门店
      config = {
        table: {
          loading: false,
          mutiSelect: false,
          radioSelect: true,
          list: shopPoiQueryt,
          rowKey: 'poiId',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'poiName',
              label: '抖音门店名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'address',
              label: '抖音门店地址',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'latitude',
              label: '纬度',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'longitude',
              label: '经度',
              minWidth: 180,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'vip': // 会员
      config = {
        treeQuery: '',
        treeApi: listlevel,
        defaultProps: {
          id: 'vipLevelId',
          label: 'vipLevelName'
        },
        queryParams: {
          vipName: undefined
        },
        tableSearch: [
          {
            width:'220px',
            type: "filters",
            tip: '会员卡号/会员名称/手机号',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'tels', label: '手机号' }
            ]
          },
          {
            seniorSearch: true,
            label: "创建时间",
            type: "datetimerange",
            model: "",
            clearable: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            seniorSearch: true,
            label: "开卡时间",
            type: "datetimerange",
            model: "",
            clearable: true,
            filterFnc: ([a, b] = []) => ({ activateTimeBegin: a, activateTimeEnd: b }),
          },
          {
            type: "numberRangeDialog",
            label: "卡号",
            filter: "startCustomerUnitPrice",
            defaultValue: '',
            model: '',
            labelWidth: 45,
            startPlaceholder:'开始卡号',
            endPlaceholder:'结束卡号',
            filterFnc: ([one, two] = []) => ({
              startCardNo: one,
              endCardNo: two,
            }),
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入会员卡号',
          list: listVipCard,
          rowKey: 'vipId',
          searchName: 'vipNo',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
            cardStatus: 0
          },
          columns: [
            {
              prop: 'vipNo',
              label: '会员卡号',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'vipName',
              label: '会员姓名',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'tel',
              label: '手机',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'vipLevelName',
              label: '会员级别',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'cardStatusName',
              label: '状态',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'allBalance',
              label: '卡内总余额',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'allFillMoney',
              label: '累计充值金额',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'usableFillMoney',
              label: '可用充值金额',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'allLargessMoney',
              label: '累计赠送金额',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'usableLargessMoney',
              label: '可用赠送金额',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'allScore',
              label: '累计积分',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'usableScore',
              label: '可用积分',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'creditMoney',
              label: '信用额度',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'fillCount',
              label: '充值次数',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'payCount',
              label: '支付次数',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'scoreCount',
              label: '积分次数',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'isCardEmitedName',
              label: '发放',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '开始使用时间',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'lastUseTime',
              label: '最近使用时间',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'sexName',
              label: '性别',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'birthday',
              label: '会员生日',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'address',
              label: '地址',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160,
              align: 'center'
            },
            { prop: 'createBy', label: '创建人', minWidth: '100' },
            { prop: 'createTime', label: '创建时间', minWidth: '160'},
            {
              prop: 'activateTime',
              label: '开卡时间',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 160,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'vipLevel': //会员级别
      config = {
        treeQuery: '',
        treeApi: undefined,
        defaultProps: {
          id: 'vipLevelId',
          label: 'vipLevelName'
        },
        queryParams: {
          vipName: undefined
        },
        search: {
          tip: '级别编号/级别名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'vipLevelNos', label: '级别编号' },
            { filter: 'vipLevelNames', label: '级别名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入会员级别编号',
          list: listlevel,
          rowKey: 'vipLevelId',
          searchName: 'vipLevelNo',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          //表格
          columns: [
            {
              prop: 'vipLevelNo',
              label: '级别编码',
              type: 'link',
              click: 'update',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'vipLevelName',
              label: '级别名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'priceCaseName',
              label: '会员价',
              minWidth: 120,
              align: 'center'
            },
            {
              type: 'icons',
              prop: 'isCanFill',
              label: '储值',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  } ; font-size: 20px; font-weight: 700;`
              })
            },
            {
              type: 'icons',
              prop: 'isCanScore',
              label: '积分',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  }; font-size: 20px; font-weight: 700;`
              })
            },
            {
              type: 'icons',
              prop: 'isCanDiscount',
              label: '折扣',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  }; font-size: 20px; font-weight: 700;`
              })
            },
            {
              type: 'icons',
              prop: 'isCanMerge',
              label: '另类卡合并',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  } ; font-size: 20px; font-weight: 700;`
              })
            },
            {
              type: 'icons',
              prop: 'isWxVipLevel',
              label: '微会员级别',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  } ; font-size: 20px; font-weight: 700;`
              })
            },
            {
              type: 'icons',
              prop: 'isDefaultWxVipLevel',
              label: '默认微会员级别',
              minWidth: 120,
              align: 'center',
              formatter: propValue => ({
                icon: propValue ? 'el-icon-check' : 'el-icon-close',
                style: `color: ${propValue ? '#41bb41' : '#ff4949'
                  } ; font-size: 20px; font-weight: 700;`
              })
            },
            {
              prop: 'statusName',
              label: '状态',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'begDate',
              label: '生效日期',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'endDate',
              label: '失效日期',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 160,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'freshlyListOrder': //现烤师傅
      config = {
        treeQuery: '',
        treeApi: undefined,
        defaultProps: {
          id: 'workerId',
          label: 'workerName'
        },
        queryParams: {
          vipName: undefined
        },
        search: {
          tip: '现烤师编码/现烤师名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'workerNo', label: '现烤师编码' },
            { filter: 'workerName', label: '现烤师名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          radioSelect: false,
          selectPlaceholder: '输入现烤师编码、现烤师名称',
          list: listOrder,
          rowKey: 'workerId',
          searchName: 'workerNo',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          //表格
          columns: [
            {
              prop: 'workerNo',
              label: '现烤师编码',
              // type: 'link',
              // click: 'update',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'workerName',
              label: '现烤师名称',
              minWidth: 150,
              align: 'center'
            },
          ]
        }
      }
      break
    case 'goods': //商品资料
      config = {
        treeQuery: '',
        treeApi: listCategoryTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'categoryId'
        },
        // queryParams: {
        //   goodsTypes: [0, 1, 2, 3, 4]
        // },
        search: {
          tip: '商品编码/商品名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'goodsNos', label: '商品编码' },
            { filter: 'goodsNames', label: '商品名称' },
            { filter: 'barcodes', label: '商品条码' },
            { filter: 'goodsSpecs', label: '商品规格' },
            { filter: 'zjms', label: '助记码' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入商品编码、商品名称、商品条码',
          list: listGoods,
          rowKey: 'goodsId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
            goodsTypes: [0, 1, 2, 3, 4]
          },
          columns: [
            {
              prop: 'goodsNo',
              label: '商品编码',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'goodsName',
              label: '商品名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'barcode',
              label: '条码',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'goodsType',
              label: '商品类型',
              minWidth: 120,
              align: 'center',
              type: 'dict',
              dict: 'goods_type'
            },
            {
              prop: 'categoryName',
              label: '商品类别',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'unitName',
              label: '基本单位',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'goodsSpec',
              label: '规格',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'weight',
              label: '重量',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'goodsModel',
              label: '型号',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'status',
              label: '商品状态',
              minWidth: 160,
              align: 'center',
              type: 'dict',
              dict: 'common_status'
            },
            {
              prop: 'qualityDays',
              label: '保质期天数',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'purPrice',
              label: '商品进价',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'salePrice',
              label: '商品售价',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'taxRate',
              label: '税率(%)',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'pricingMode',
              label: '计价方式',
              minWidth: 160,
              align: 'center',
              type: 'dict',
              dict: 'pricing_mode'
            },
            {
              prop: 'isManageStock',
              label: '是否管库存',
              minWidth: 110,
              align: 'center',
              type: 'dict',
              dict: 'App_is_manage_stock'
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 160,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'o2oGoods': //商品资料
      config = {
        treeQuery: '',
        treeApi: categoryLiStTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'categoryId'
        },
        queryParams: {
          status: 0,
          goodsType: 1
        },
        search: {
          tip: '商品编码/商品名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'goodsNos', label: '商品编码' },
            { filter: 'goodsNames', label: '商品名称' },
            { filter: 'barcodes', label: '商品条码' },
            { filter: 'zjms', label: '助记码' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入商品编码、商品名称、商品条码',
          list: goodsList,
          rowKey: 'goodsId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
          },
          columns: [
            {
              prop: 'goodsNo',
              label: '商品编码',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'goodsName',
              label: '商品名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'barcode',
              label: '条码',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'goodsType',
              label: '商品类型',
              minWidth: 120,
              align: 'center',
              type: 'dict',
              dict: 'goods_type'
            },
            {
              prop: 'categoryName',
              label: '商品类别',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'unitName',
              label: '基本单位',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'goodsSpec',
              label: '规格',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'weight',
              label: '重量',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'goodsModel',
              label: '型号',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'status',
              label: '商品状态',
              minWidth: 160,
              align: 'center',
              type: 'dict',
              dict: 'common_status'
            },
            {
              prop: 'qualityDays',
              label: '保质期天数',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'purPrice',
              label: '商品进价',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'salePrice',
              label: '商品售价',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'taxRate',
              label: '税率(%)',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'pricingMode',
              label: '计价方式',
              minWidth: 160,
              align: 'center',
              type: 'dict',
              dict: 'pricing_mode'
            },
            {
              prop: 'isManageStock',
              label: '是否管库存',
              minWidth: 110,
              align: 'center',
              type: 'dict',
              dict: 'App_is_manage_stock'
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 160,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'shop': //门店
      config = {
        treeQuery: '',
        treeApi: getDeptTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'shopId'
        },
        queryParams: {
          goodsNo: undefined
        },
        search: {
          tip: '门店编号/门店名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'shopNos', label: '门店编号' },
            { filter: 'shopNames', label: '门店名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入门店编号、门店名称、助记码',
          list: listShopInfo,
          rowKey: 'shopId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'shopNo',
              label: '门店编码',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'shopName',
              label: '门店名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'shopTypeName',
              label: '门店类型',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'useArea',
              label: '门店面积',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'linkEmployeeName',
              label: '联系人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'linkEmployeeTel',
              label: '联系电话',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'totalAddress',
              label: '门店地址',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'sortNo',
              label: '排序',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160,
              align: 'center',
              type: 'dict',
              dict: 'common_status'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 110,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'o2oShop': //门店
      config = {
        treeQuery: '',
        treeApi: getDeptTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'shopId'
        },
        queryParams: {
          goodsNo: undefined
        },
        search: {
          tip: '门店编号/门店名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'shopNos', label: '门店编号' },
            { filter: 'shopNames', label: '门店名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入门店编号、门店名称、助记码',
          list: shopInfoList,
          rowKey: 'shopId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'shopNo',
              label: '门店编码',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'shopName',
              label: '门店名称',
              minWidth: 150,
              align: 'center'
            },
            {
              prop: 'shopTypeName',
              label: '门店类型',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'useArea',
              label: '门店面积',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'linkEmployeeName',
              label: '联系人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'linkEmployeeTel',
              label: '联系电话',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'totalAddress',
              label: '门店地址',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'sortNo',
              label: '排序',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160,
              align: 'center',
              type: 'dict',
              dict: 'common_status'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 160,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 110,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 110,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'brand': //品牌
      config = {
        treeQuery: '',
        treeApi: undefined,
        defaultProps: {},
        queryParams: {
          goodsNo: undefined
        },
        search: {
          tip: '品牌编号/品牌名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'brandNos', label: '品牌编号' },
            { filter: 'brandNames', label: '品牌名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入品牌编号',
          list: listBrand,
          rowKey: 'brandId',
          searchName: 'brandNo',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15,
            goodsType: ['1']
          },
          columns: [
            {
              prop: 'brandNo',
              label: '品牌编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'brandName',
              label: '品牌名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center',
              type: 'dict',
              dict: 'goods_type'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 120,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'category': //类别弹窗
      config = {
        treeQuery: '',
        treeApi: listCategoryTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'categoryId'
        },
        queryParams: {
          goodsNo: undefined
        },
        search: {
          tip: '类别编码/类别名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'categoryNos', label: '类别编码' },
            { filter: 'categoryNames', label: '类别名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '输入类别编号',
          list: listCategory,
          rowKey: 'categoryId',
          searchName: 'categoryNo',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'categoryNo',
              label: '类别编码',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'categoryName',
              label: '类别名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 155,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'coupon': //优惠券
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {
          goodsNo: undefined
        },
        search: {
          tip: '优惠券编号/优惠券名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'couponCaseNos', label: '优惠券编号' },
            { filter: 'couponCaseNames', label: '优惠券名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          listNo: true,
          selectPlaceholder: '请输入优惠券编号/优惠券名称',
          list: CouponList,
          rowKey: 'couponCaseId',
          searchName: 'couponCaseNo',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'couponCaseNo',
              label: '优惠券方案编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'couponCaseName',
              label: '优惠券方案名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'couponCaseTypeName',
              label: '优惠券类型',
              minWidth: 180,
              align: 'center',
              // type: 'dict',
              // dict: 'coupon_case_type'
            },
            {
              prop: 'couponModeName',
              label: '优惠券形式',
              minWidth: 120,
              align: 'center',
              // type: 'dict',
              // dict: 'coupon_mode'
            },
            {
              prop: 'isStop',
              label: '方案启用',
              minWidth: 120,
              align: 'center',
              formatter: propValue => (propValue ? '禁用' : '启用')
            },
            {
              prop: 'couponDesc',
              label: '优惠券说明',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'validBegTime',
              label: '生效时间',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'validEndTime',
              label: '失效时间',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'couponStatus',
              label: '优惠券状态',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'couponCount',
              label: '优惠券发放总数',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'scrapCount',
              label: '作废',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'drawCount',
              label: '已领',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'usedCount',
              label: '已核销',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'sellCount',
              label: '销售',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'sellBackCount',
              label: '销售退回',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'giveCount',
              label: '转赠',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 120,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'couponPackage': //优惠券包
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {
          goodsNo: undefined
        },
        search: {
          tip: '全部/券包编号/券包名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'packageNos', label: '券包编号' },
            { filter: 'packageNames', label: '券包名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入券包名称',
          list: packageListAPI,
          rowKey: 'packageId',
          searchName: 'packageName',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'packageName',
              label: '券包名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'couponCaseName',
              label: '状态',
              minWidth: 180,
              align: 'center',
              formData: propValue => {
                propValue == 1 ? '启用' : '禁用'
              }
            },
            {
              prop: 'packagePrice',
              label: '券包售价',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center',
              type: 'dict',
              dict: 'coupon_mode'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center',
              type: 'dict',
              dict: 'coupon_mode'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 120,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'store': //仓库
      config = {
        treeQuery: '',
        treeApi: getDepTree,
        defaultProps: {
          id: 'id',
          label: 'label',
          searchId: 'deptId'
        },
        queryParams: {},
        search: {
          tip: '全部/仓库编码/仓库名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'storeNos', label: '仓库编码' },
            { filter: 'storeNames', label: '仓库名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入仓库编码、仓库名称',
          list: listStore,
          rowKey: 'storeId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'storeNo',
              label: '仓库编码',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'storeName',
              label: '仓库名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'deptName',
              label: '所属机构',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'employeeName',
              label: '联系人',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'storeTel',
              label: '联系电话',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'storeAddress',
              label: '地址',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center',
              type: 'dict',
              dict: 'coupon_mode'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center',
              type: 'dict',
              dict: 'coupon_mode'
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 155,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'produceStore': //生产仓库
      config = {
        treeQuery: '',
        treeApi: undefined,
        defaultProps: {
          id: 'produceDeptId',
          label: 'produceDeptName',
          searchId: 'produceDeptId'
        },
        queryParams: {},
        search: {
          tip: '全部/生产仓库编号/生产仓库名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'produceStoreNos', label: '生产仓库编号' },
            { filter: 'produceStoreNames', label: '生产仓库名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入生产仓库编号、生产仓库名称',
          list: produceStoreList,
          rowKey: 'lineId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'produceStoreNo',
              label: '生产仓库编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'produceStoreName',
              label: '生产仓库名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'storeTypeName',
              label: '仓库属性',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'produceDeptName',
              label: '生产工厂',
              minWidth: 160,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'customer': //客户
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '全部/客户编号/客户名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'partnerNos', label: '客户编号' },
            { filter: 'partnerNames', label: '客户名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入客户名称',
          list: listCustomer,
          rowKey: 'partnerId',
          searchName: 'partnerName',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'partnerNo',
              label: '客户编码',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'partnerName',
              label: '客户名称',
              minWidth: 180
            },
            {
              prop: 'partnerTypeName',
              label: '客户类型',
              minWidth: 100,
              align: 'center',
              formData: propValue => {
                propValue == 0 ? '客供' : '客户'
              }
            },
            {
              prop: 'areaName',
              label: '客户区域',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'groupName',
              label: '客户组别',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'saleEmployee',
              label: '业务员',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'priceCaseName',
              label: '价格方案',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'payTypeName',
              label: '结算方式',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'statusName',
              label: '状态',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'supplier': //供应商
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '全部/供应商编号/供应商名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'partnerNos', label: '供应商编号' },
            { filter: 'partnerNames', label: '供应商名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入供应商名称',
          list: listSupplier,
          rowKey: 'partnerId',
          searchName: 'partnerName',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'partnerNo',
              label: '供应商编码',
              minWidth: 180
            },
            {
              prop: 'partnerName',
              label: '供应商名称',
              minWidth: 180
            },
            // {
            //   prop: 'partnerTypeName',
            //   label: '供应商类型',
            //   minWidth: 100
            // },
            {
              formatter: (v, row) =>
                row?.areaId == 0 ? '全部区域' : row?.areaName,
              prop: 'areaName',
              label: '供应商区域',
              minWidth: 120
            },
            {
              formatter: (v, row) =>
                row?.groupId == 0 ? '全部分组' : row?.groupName,
              prop: 'groupName',
              label: '供应商组别',
              minWidth: 120
            },
            {
              prop: 'priceCaseName',
              label: '价格方案',
              minWidth: 120
            },
            {
              formatter: (v, row) =>
                row?.payType == 0
                  ? '现金'
                  : row?.payType == 1
                    ? '支票'
                    : '银行账户',
              prop: 'payType',
              label: '结算方式',
              minWidth: 120
            },
            {
              formatter: (v, row) =>
                row?.payType == 0
                  ? '正常'
                  : row?.payType == 1
                    ? '停用'
                    : '删除',
              prop: 'status',
              label: '状态',
              minWidth: 120
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 155
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      }
      break
    case 'produceOrder': //生产班次
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '全部/生产班次编号/生产班次名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'produceOrderNos', label: '生产班次编号' },
            { filter: 'produceOrderNames', label: '生产班次名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入生产班次编号或名称',
          list: produceOrderList,
          rowKey: 'produceOrderId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'produceOrderNo',
              label: '生产班次编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'produceOrderName',
              label: '生产班次名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'produceOrderBegTime',
              label: '开始时间',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'produceOrderEndTime',
              label: '结束时间',
              minWidth: 120,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'guideMan': //导购员
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '全部/导购员编号/导购员名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'guideManNos', label: '导购员编号' },
            { filter: 'guideManNames', label: '导购员名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入导购员编号或名称',
          list: guideManListAPI,
          rowKey: 'guideManId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'guideManNo',
              label: '导购员编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'guideManName',
              label: '导购员名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'commissionTypeName',
              label: '提成方式',
              minWidth: 100,
              align: 'center'
            },
            {
              prop: 'commissionBillName',
              label: '提成方案名',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'commissionSaleRate',
              label: '销售提成率%',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'commissionFillRate',
              label: '充值提成率%',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'totalUsableCommission',
              label: '未结算提成%',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'totalSettlementCommission',
              label: '累计结算提成%',
              minWidth: 120,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'cashier': //收银员
      config = {
        treeQuery: '',
        treeApi: getCashierTree,
        defaultProps: {
          id: 'id',
          label: 'label'
        },
        queryParams: {},
        search: {
          tip: '全部/收银员编号/收银员名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'cashierNos', label: '收银员编号' },
            { filter: 'cashierNames', label: '收银员名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入收银员编号或名称',
          list: listShopCashier,
          rowKey: 'cashierId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'cashierNo',
              label: '收银员编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'cashierName',
              label: '收银员姓名',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'telephone',
              label: '手机号',
              minWidth: 100,
              align: 'center'
            },
            {
              prop: 'shopNameStr',
              label: '所属门店',
              minWidth: 100,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'paymode': //收款方式
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '全部/收款方式编号/收款方式名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'payModeNos', label: '收款方式编号' },
            { filter: 'payModeNames', label: '收款方式名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入收款方式编号或名称',
          list: listPaymode,
          rowKey: 'payModeId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'payModeNo',
              label: '编号',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'payModeName',
              label: '名称',
              minWidth: 180,
              align: 'center'
            },
            {
              prop: 'sortNo',
              label: '门店排序',
              minWidth: 100,
              align: 'center'
            },
            {
              prop: 'hotkey',
              label: '快捷键',
              minWidth: 100,
              align: 'center'
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120,
              align: 'center'
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155,
              align: 'center'
            }
          ]
        }
      }
      break
    case 'machine': //收银机
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '全部/收款方式编号/收款方式名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'machineNos', label: '收银机编号' },
            { filter: 'machineNames', label: '收银机名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入收款方式编号或名称',
          list: listMachine,
          rowKey: 'machineId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'machineNo',
              label: '收银机编号',
              minWidth: 180
            },
            {
              prop: 'machineName',
              label: '收银机名称',
              minWidth: 180
            },
            {
              prop: 'shopNo',
              label: '门店编码',
              minWidth: 180
            },
            {
              prop: 'shopName',
              label: '门店名称',
              minWidth: 180
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 100
            },
            {
              prop: 'deviceName',
              label: '计算机名称',
              minWidth: 120
            },
            {
              prop: 'applicationTerminalType',
              label: '应用终端类型',
              minWidth: 120
            },
            {
              prop: 'downloadTerminal',
              label: '下载终端',
              minWidth: 120
            },
            {
              prop: 'startUseTime',
              label: '开始使用时间',
              minWidth: 130
            },
            {
              prop: 'lastUseTime',
              label: '最近使用时间',
              minWidth: 120
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 130
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 130
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      }
      break
   case 'product': //产品
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        // search: {
        //   tip: '全部/产品编号/产品名称',
        //   model: '',
        //   filters: [
        //     { filter: 'query', label: '全部' },
        //     { filter: 'productNos', label: '产品编号' },
        //     { filter: 'productNames', label: '产品名称' }
        //   ]
        // },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入产品名称',
          list: getOrderList,
          rowKey: 'productId',
          searchName: 'productName',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            { prop: 'productName', label: '产品名称', minWidth: 160 },
            { prop: 'logoUrl', label: '产品图片', minWidth: 160, type: 'image', },
            {
              prop: 'productCalcType',
              formatter: (v, row) => row.productCalcType == '2' ? '数量或次数' : '包年包月',
              label: '计费模式',
              minWidth: 100
            },
            { prop: 'initializeUsers', label: '初始用户数', minWidth: 100 },
            { prop: 'maxUsers', label: '最大用户数', minWidth: 100 },
            { prop: 'initializeShops', label: '初始门店数', minWidth: 100 },
            { prop: 'maxShops', label: '最大门店数', minWidth: 100 },
            { prop: 'status', label: '状态', minWidth: 100 },
            { prop: 'sortNo', label: '排序', minWidth: 100 },
            { prop: 'createTime', label: '创建时间', minWidth: 180 }
          ]
        }
      }
      break
    case 'belongProduct': //所属产品
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        treeProps: {
          children: 'children',
          hasChildren: 'hasChildren'
        },
        search: {
          tip: '全部/产品编号/产品名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'productNos', label: '产品编号' },
            { filter: 'productNames', label: '产品名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入所属产品名称',
          list: getBelongProduct,
          rowKey: 'productId',
          searchName: 'productName',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'productNo',
              type: 'link',
              align: 'left',
              click: 'link',
              label: '产品编号',
              minWidth: '160'
            },
            { prop: 'productName', label: '产品名称', minWidth: '160' },
            {
              prop: 'logoUrl',
              type: 'image',
              label: '产品图片',
              minWidth: '160'
            },
            { prop: 'initializeUsers', label: '初始用户数', minWidth: '120' },
            { prop: 'maxUsers', label: '最大门店数', minWidth: '120' },
            { prop: 'initializeShops', label: '初始门店数', minWidth: '120' },
            { prop: 'maxShops', label: '最大门店数', minWidth: '120' },
            {
              prop: 'status',
              type: 'switch',
              label: '上线状态',
              minWidth: '120',
              active: '0',
              inactive: '1',
              disabled:true
            },
            { prop: 'sort', label: '排序', minWidth: '120' },
            { prop: 'createTime', label: '创建时间', minWidth: '160' }
          ]
        }
      }
      break
    case 'account': //选择账户
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '全部/账户编码/账户名称',
          model: '',
          filters: [
            { filter: 'query', label: '全部' },
            { filter: 'bankAccountNos', label: '账户编码' },
            { filter: 'bankAccountNames', label: '账户名称' }
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入账户名称',
          list: listBankAccount,
          rowKey: 'bankAccountId',
          searchName: 'bankAccountName',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            { prop: 'bankAccountNo', label: '账户编码', minWidth: '160' },
            { prop: 'bankAccountName', label: '账户名称', minWidth: '160' },
            { prop: 'bankNo', label: '银行账号', minWidth: '160' },
            { prop: 'bankTitle', label: '开户名', minWidth: '160' },
            { prop: 'bankName', label: '开户行', minWidth: '160' }
          ]
        }
      }
      break
    case 'mainGoods': //选择主要商品
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: '商品编号/商品名称/商品条码',
          model: '',
          filters: [
            { filter: 'relateGoodsNos', label: '商品编号' },
            { filter: 'relateGoodsNames', label: '商品名称' },
            { filter: 'relateBarcodes', label: '商品条码' },
          ]
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入商品名称',
          list: listMainGoods,
          rowKey: 'lineId',
          searchName: 'goodsName',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            {
              prop: 'goodsNo', label: '商品编码', minWidth: '160',
              formatter: (v, row) => row.relateGoodsInfo?.goodsNo ? row.relateGoodsInfo.goodsNo : '',
            },
            {
              prop: 'goodsName', label: '商品名称', minWidth: '160',
              formatter: (v, row) => row.relateGoodsInfo?.goodsName ? row.relateGoodsInfo.goodsName : '',
            },
            {
              prop: 'barcode', label: '商品条码', minWidth: '160',
              formatter: (v, row) => row.relateUnitInfo?.barcode ? row.relateUnitInfo.barcode : '',
            },
            {
              prop: 'goodsSpec', label: '规格', minWidth: '160',
              formatter: (v, row) => row.relateUnitInfo?.goodsSpec ? row.relateUnitInfo.goodsSpec : '',
            },
            {
              prop: 'goodsSpec', label: '单位', minWidth: '160',
              formatter: (v, row) => row.relateUnitInfo?.unitName ? row.relateUnitInfo.unitName : '',
            },
            {
              prop: 'goodsSpec', label: '进价', minWidth: '160',
              formatter: (v, row) => row.relateUnitInfo?.purPrice ? row.relateUnitInfo.purPrice : '',
            },
            {
              prop: 'goodsSpec', label: '售价', minWidth: '160',
              formatter: (v, row) => row.relateUnitInfo?.salePrice ? row.relateUnitInfo.salePrice : '',
            },
          ]
        }
      }
      break
    case 'listRpType': //选择收入支出类别
      config = {
        treeQuery: '',
        treeApi: undefined,
        queryParams: {},
        search: {
          tip: "全部/收入编码/收入名称",
          model: "",
          filters: [
            { filter: "query", label: "全部" },
            { filter: "rpTypeNos", label: "收入编码" },
            { filter: "rpTypeNames", label: "收入名称" },
          ],
        },
        table: {
          loading: false,
          mutiSelect: true,
          selectPlaceholder: '请输入',
          list: listRpType,
          rowKey: 'rpTypeId',
          searchName: 'query',
          rowClick: true,
          check: [],
          pagination: {
            total: 0,
            pageNum: 1,
            pageSize: 15
          },
          columns: [
            { prop: "rpTypeNo", label: "收入编码", minWidth: 180, },
            { prop: "rpTypeName", label: "收入名称", minWidth: 155, },
            { prop: "rpTypeName", label: "收入分类名称", minWidth: 180, },
            { prop: "rpTypeCategoryId", label: "收入分类编码", minWidth: 155, },
            { prop: "rpTypeCategoryName", label: "分类名称", formatter: (v, row) => row.rpTypeCategoryInfo ? row.rpTypeCategoryInfo.rpTypeCategoryName : '', minWidth: 120, },
            { prop: "isStop", label: "状态", formatter: (v, row) => row.isStop ? '禁用' : '启用', minWidth: 120, },
          ]
        }
      }
      break
    default:
      break
  }

  const selectOpt = { ...deepCopy(config), ...deepCopy(option) }
  // console.log(deepCopy(option))
  return {
    filterable: true,
    ...selectOpt,
    option: {
      ...deepCopy(config)?.option,
      ...deepCopy(option)?.option
    }
  }
}
