import {getColumnConfigDetail,saveColumnConfig} from "@/api/tableColumnConfig"
import {arrayObjectCopy, mergeExcept} from "@/utils"
// 表格列配置
export default {
    data(){
        return {
            configId:'',
            configIdOld:'',
            gridColumns:[],
            gridColumnsCopy:[],//备份一份表格列数据
            formDetailsCopy:[],//表格数据备份
        }
    },
    created(){
        let routePath = this.$route.path;
        let routePathArr = routePath.split('/');
        routePathArr.forEach(item=>{
            this.configId+=item+'_';
        })
        if(this.configId) this.configIdOld = this.configId;
        
    },
    methods:{
        //读取当前表格列配置
        async getColumnConfigDetail(){
            this.gridColumns = this.originColumns;
            this.gridColumnsCopy = arrayObjectCopy(this.gridColumns);
            try{     
                if(!this.configId||this.configId.indexOf('printTemplate')>-1){
                    return;
                }
                let res = await getColumnConfigDetail({gridUuid:this.configId});
                if(res&&res.data&&res.data.gridItems){
                    let arr = res.data.gridItems;
                    //页面上新加入了字段
                    const diffAdd = this.originColumns.filter(obj2 => !arr.some(obj1 => obj1.fieldName === obj2.fieldName));//找出新添加的
                    // console.log(this.originColumns,'diffsafasdfasdfasfd');
                    this.originColumns.forEach((item,index)=>{
                        diffAdd.forEach(pro=>{
                            if(item.fieldName==pro.fieldName){
                                pro.curIndex = index;
                            }
                        })
                    });
                    // console.log(diffAdd,'diffsafasdfasdfasfd');
                    diffAdd.forEach(item=>{
                        arr.splice(item.curIndex, 0, item);
                    })
                    this.gridColumns = arr;
                }
                //刷新表格
                this.handleConfigGrid(this.gridColumns);
            }catch(e){
                //刷新表格
                this.handleConfigGrid(this.gridColumns);
            }
        },
        //保存当前表格列配置
        async handleSaveColumnConfig(list){
            list.forEach((item,index)=>{
                item.fieldSort=index;
            })
            let res = await saveColumnConfig({gridUuid:this.configId,gridItems:list||[]});
            if(res.code==200){
                this.$message.success('操作成功');
                this.handleConfigGrid(list)
            }
        },
        //因为后台返回的保存字段是没有自己设置的那些默认项的，所以要合并一下
        handleConfigGrid(columns){
            columns.forEach((item,index)=>{
                this.originColumns.forEach(pro=>{
                    if(item.fieldName==pro.fieldName && item.fieldCaption==pro.fieldCaption){
                        //columns[index] = mergeExcept(item,pro,['fieldIsDisplay','fieldCustomCaption','fieldFixedFront','fieldFixedBack']);
                        this.$set(columns,index,mergeExcept(item,pro,['fieldIsDisplay','fieldCustomWidth','fieldCustomCaption','fieldFixedFront','fieldFixedBack']));
                    }
                })
            })
            this.gridColumns = columns;
            if(this.config&&this.config.columns){
                this.config.columns = arrayObjectCopy(columns);
                this.config.columns.forEach(item=>{
                    item.label=item.fieldCustomCaption;
                    //item.width=item.fieldCustomWidth;
                    item.minWidth = item.fieldCustomWidth;
                    if(item.fieldFixedFront){
                        item.fixed='left';
                    }
                    if(item.fieldFixedBack){
                        item.fixed='right';
                    }
                })
                //console.log(this.gridColumns,this.config)
            }
            this.gridColumnsCopy = arrayObjectCopy(this.gridColumns);
            if(this.disabled){
                this.gridColumns.forEach(item=>{
                    item.disabled=true;
                })
            }
            setTimeout(()=>{
                //刷新表格列(刷新表格会丢失原有的表格数据，先备份)
                if(this.form&&this.form.details.length>0){
                    this.formDetailsCopy= JSON.parse(JSON.stringify(this.form.details));
                }
                this.tableKey = Date.now();
                //刷新表格以后重新给表格赋值
                setTimeout(()=>{
                    //console.log(this.$refs.myTable)
                    //console.log(this.formDetailsCopy);
                    //此处为详情中的编辑表格
                    if(this.$refs.myTable){
                        this.$refs.myTable.form.details =JSON.parse(JSON.stringify(this.formDetailsCopy)); 
                    }
                    //此处为列表中的表格组件
                    if(this.$refs.table){
                        this.$refs.table.$refs.mutipleTable.doLayout(); 
                    }
                },100)}
            ,500)
        },
    },
}